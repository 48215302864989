<template>
    <div>

        <div class="card bd-0 mg-b-20" style="background: transparent;">
            <div class="card-header bg-primary tx-white">
                <ul class="nav nav-tabs nav-tabs-for-dark card-header-tabs">
                    <li v-for="t in tabs" class="nav-item" :class="{'active': t.showing}">
                        <a class="nav-link text-white bd-0 pd-y-8 ft-left" v-bind:class="{'active pd-y-8': t.showing}" href="javascript:void(0);" @click="selectTab(t.name)">{{t.name}}</a>
                        <a v-if="t.name !== 'Requests'" class="nav-link text-white bd-0 pd-y-8 ft-right tab-close" style="padding-left: 0; padding-right: 0;" v-bind:class="{'active pd-y-8': t.showing}" href="javascript:void(0);" @click="closeTab(t.name)"><i class="fas fa-times"></i></a>
                        <a v-if="t.name !== 'Requests'" class="nav-link text-white bd-0 pd-y-8 ft-right tab-reload" style="padding-left: 0; padding-right: 0; margin-left: -1rem;" :class="{'active pd-y-8': t.showing}" href="javascript:void(0);" @click="reloadClaim(t.name)"><i class="fa-solid fa-repeat"></i>
                        </a>
                    </li>
                </ul>
            </div><!-- card-header -->
            <div class="card-body" style="background: transparent;">
                <claim v-for="tab in claimTabs" v-show="tab.showing" :key="tab.id" :claim_id="parseInt(tab.id)" :client_id="parseInt(tab.client_id)" @open-claim="selectClaim" :presence="presence.users"></claim>
                <div v-show="selectedTab?.name === 'Requests'" class="row mg-t-20">
                    <div class="col-lg-12">
                        <div class="alert alert-info mg-b-10" role="alert">
                            <button type="button" class="close" data-bs-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <strong class="d-block d-sm-inline-block-force">Heads up!</strong> When you accept or reject a request, the user will receive an email notifying them. This will include the reason provided if you reject it.
                        </div><!-- alert -->
                        <pagination v-bind:data="cancellations" @search="search"></pagination>

                        <table width="100%" class="table table-striped table-hover tx-white">
                            <thead class="thead-colored thead-primary">
                            <tr>
                                <th>Created At</th>
                                <th>User Requested</th>
                                <th>Claim Reference</th>
                                <th>Reason</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="entry in cancellations.data" v-if="!searching" style="cursor: pointer">
                                <th>{{ entry.created_at_formatted }}</th>
                                <th>{{entry.user_email}}</th>
                                <td style="cursor: pointer;" class="claim-link" @click="selectClaim(entry)">{{entry.claim.reference}}</td>
                                <td>{{entry.reason}}</td>
                                <td>
                                    <button class="btn btn-sm btn-primary" @click="acceptRequest(entry)">Accept Request</button>
                                    <button class="btn btn-sm btn-danger" @click="rejectRequest(entry)">Reject Request</button>
                                </td>
                            </tr>
                            <tr v-if="cancellations.data.length < 1 && !searching">
                                <td colspan="4" class="text-center">No Cancellations</td>
                            </tr>
                            <tr v-if="searching">
                                <td colspan="4" class="text-center">
                                    <div class="spinner-border text-secondary m-2" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <pagination v-bind:data="cancellations" @search="search"></pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Pagination from "../../components/Pagination";
import Swal from "sweetalert2";
import Claim from "../claims/Claim";
import axios from "axios";
import authHeader from "@/services/auth-header";
import {DateTime} from 'luxon';
import Socket from "@/services/socket";

export default {
    name: "AdminCancellations",
    components: {Claim, Pagination},
    data(){
        return {
            cancellations: {
                data: [],
                currPage: 1,
                totalResults: 0,
                from: 0,
                to: 0,
                perPage: 0,
                lastPage: 1
            },
            searching: true,
            tabs: [
                {
                    name: "Requests",
                    claim: false,
                    id: -1,
                    showing: true
                }
            ],
            socket: null,
            presence: {
                users: [],
                interval: null
            },
        }
    },
    created(){
        this.socket = new Socket(this.$store.state.auth.user.ws_url);
    },  
    unmounted(){
        this.socket.off('open-claim', (message) => {
            this.presence.users.push(message.content);
        });
        this.socket.off('close-claim', (message) => {
            this.presence.users = this.presence.users.filter(u => {
                return !(
                    u.reference == message.reference &&
                    u.user.id == message.content.user.id
                );
            });
        });
        if(!this.$store.state.showClientSelector){
            this.$store.commit('toggleClientSelector');
        }
        clearInterval(this.presence.interval);
    },  
    mounted() {
        this.socket.on('open-claim', (message) => {
            message._ts = Date.now();
            if(this.presence.users.filter(u => {
                return u.reference == message.reference &&
                    u.user.id == message.user.id
            }).length > 0){
                this.presence.users.filter(u => {
                    return u.reference == message.reference &&
                        u.user.id == message.user.id
                })[0]._ts = Date.now();
                return;
            }
            this.presence.users.push(message);
        });
        this.socket.on('close-claim', (message) => {
            this.presence.users = this.presence.users.filter(u => {
                return !(
                    u.reference == message.reference &&
                    u.user.id == message.user.id
                );
            });
        });
        this.presence.interval = setInterval(() => {
            this.tabs.forEach((tab) => {
                if(tab.name !== 'Requests'){
                    this.socket.sendToGroup('claims', {"event": "open-claim", "content": {
                        "reference": tab.name,
                        "user": {
                            "id": this.$store.state.auth.user.id,
                            "email": this.$store.state.auth.user.email
                        }
                    }});
                }
            });

            this.presence.users = this.presence.users.filter((u) => {
                return Date.now() - u._ts < 15000;
            });
        }, 5000);
        this.search();
    },
    methods: {
        search(page = 1){
            this.searching = true;
            axios.get(`https://api.varsanpr.com/api/cancellations?page=${page}`, {
                headers: authHeader()
            })
                .then(response => {
                    response.data.cancellations.forEach((c) => {
                        c.created_at_formatted = DateTime.fromSeconds(c.created_at).toLocaleString(DateTime.DATETIME_MED);
                    });
                    this.cancellations.data = response.data.cancellations;
                    this.cancellations.currPage = response.data.pagination.page;
                    this.cancellations.totalResults = response.data.pagination.total;
                    this.cancellations.from = (this.cancellations.currPage - 1) * response.data.pagination.showing + 1;
                    this.cancellations.to = this.cancellations.from + response.data.pagination.showing;
                    this.cancellations.perPage = response.data.pagination.showing;
                    this.cancellations.lastPage = response.data.pagination.lastPage;
                    this.searching = false;
                })
                .catch(error => {
                    this.$error("Failed loading cancellation requests.", error);
                });
        },
        async acceptRequest(entry){

            if(entry.claim.type == 'parking' || entry.claim.type == 'evparking'){
                let {value: category} = await Swal.fire({
                title: 'Please select a cancellation category',
                text: 'This will be used by customers to understand the reasons their claims are being cancelled. If you are unsure which one to select please speak to your manager.',
                icon: 'warning',
                input: 'select',
                inputValue: 'Other',
                inputOptions: {
                    'Duplicate Claim': 'Duplicate Claim',
                    'Tampered Plate': 'Tampered Plate',
                    'Cloned Plate': 'Cloned Plate',
                    'Fake Plate': 'Fake Plate',
                    'Foreign Plate': 'Foreign Plate',
                    'No Plate': 'No Plate',
                    'Requested by Site': 'Requested by Site',
                    'Requested by Head Office': 'Requested by Head Office',
                    'Incorrect Submission': 'Incorrect Submission',
                    'Other': 'Other'
                },
            })

                  axios.post(`https://api.varsanpr.com/api/cancellations/accept`, {
                      claim_id: entry.claim.id,
                      user_id: entry.user_id,
                      client_id: entry.client_id,
                      paid_on_site: false,
                        category: category
                  }, {
                    headers: authHeader()
                  })
                      .then(response => {
                          this.$success("Cancellation has been accepted.");
                          this.search(this.cancellations.currPage);
                      })
                      .catch(error => {
                          console.log(error);
                          this.$error("Failed to cancel claim.", error);
                      })
            }else{
                await Swal.fire({
                title: `Is this being cancelled because it was Paid on Site?`,
                icon: "warning",
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: 'No'
            })
            .then(async (result) => {

                if(result.isDismissed){
                    return;
                }

              if(result){

                let {value: category} = await Swal.fire({
                title: 'Please select a cancellation category',
                text: 'This will be used by customers to understand the reasons their claims are being cancelled. If you are unsure which one to select please speak to your manager.',
                icon: 'warning',
                input: 'select',
                inputValue: 'Other',
                inputOptions: {
                    'Duplicate Claim': 'Duplicate Claim',
                    'Tampered Plate': 'Tampered Plate',
                    'Cloned Plate': 'Cloned Plate',
                    'Fake Plate': 'Fake Plate',
                    'Foreign Plate': 'Foreign Plate',
                    'No Plate': 'No Plate',
                    'Requested by Site': 'Requested by Site',
                    'Requested by Head Office': 'Requested by Head Office',
                    'Incorrect Submission': 'Incorrect Submission',
                    'Other': 'Other'
                },
            })

                  axios.post(`https://api.varsanpr.com/api/cancellations/accept`, {
                      claim_id: entry.claim.id,
                      user_id: entry.user_id,
                      client_id: entry.client_id,
                      paid_on_site: result.isConfirmed,
                        category: category
                  }, {
                    headers: authHeader()
                  })
                      .then(response => {
                          this.$success("Cancellation has been accepted.");
                          this.search(this.cancellations.currPage);
                      })
                      .catch(error => {
                          console.log(error);
                          this.$error("Failed to cancel claim.", error);
                      })
              }

            });
            }

        },
        async rejectRequest(entry){
            const {value: reason} = await Swal.fire({
                title: `Please explain your reason for rejecting this request`,
                input: 'textarea',
                inputPlaceholder: 'Please explain why you are rejecting this request',
                showCancelButton: true
            });

            if(reason){
                axios.post(`https://api.varsanpr.com/api/cancellations/reject`, {
                    reason: reason,
                    claim_id: entry.claim.id,
                    user_id: entry.user_id,
                    client_id: entry.client_id
                }, {
                    headers: authHeader()
                })
                    .then(response => {
                        this.$success("Cancellation request rejected.");
                        this.search(this.cancellations.currPage);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$error("Unable to reject request.");
                    });
            }
        },
        selectTab(t){
            let findTab = this.tabs.filter(tab => {
                return tab.name === t;
            })[0];

            if(!findTab) return;

            this.tabs.forEach(tab => {
                tab.showing = false;
            });

            findTab.showing = true;

        },
        closeTab(t){
            let findTab = this.tabs.filter(tab => {
                return tab.name === t;
            })[0];

            if(!findTab) return;

            if(findTab.showing){
                this.tabs.forEach(tab => {
                    tab.showing = tab.name === 'Requests';
                });
            }

            this.socket.sendToGroup('claims', {"event": "close-claim", "content": {
                "reference": findTab.name,
                "user": {
                    "id": this.$store.state.auth.user.id,
                    "email": this.$store.state.auth.user.email
                }
            }});

            this.tabs = this.tabs.filter(tab => {
                return tab.id !== findTab.id;
            });

            if(!this.$store.state.showClientSelector && this.tabs.length < 2){
                this.$store.commit('toggleClientSelector');
            }
        },
        reloadClaim(t){
        let findTab = this.tabs.filter(tab => {
            return tab.name === t;
        })[0];

        if(!findTab) return;

        let component = this.$refs[`claim-${findTab.id}`][0];
        component.loadClaim(true);
    },
        selectClaim(claim){
            console.log(`Opening Claim.`, claim);
            // $router.push(`/claims/management/${claim.id}`);
            let existingCheck = this.tabs.filter(t => {
                return t.id == claim.claim_id;
            })[0];
            if(existingCheck){
                this.tabs.forEach(t => {
                    t.showing = t.id === claim.claim_id;
                });
                return;
            }
            this.tabs.forEach(t => {
                t.showing = false;
            });
            if(claim.claim){
                this.tabs.push({
                    name: claim.claim.reference,
                    id: claim.claim_id,
                    client_id: claim.client_id,
                    claim: true,
                    showing: true
                });
            }else{
                this.tabs.push({
                    name: claim.reference,
                    id: claim.id,
                    client_id: claim.client_id,
                    claim: true,
                    showing: true
                });
            }
            this.socket.sendToGroup('claims', {"event": "open-claim", "content": {
                "reference": claim.reference,
                "user": {
                    "id": this.$store.state.auth.user.id,
                    "email": this.$store.state.auth.user.email
                }
            }});

            if(this.$store.state.showClientSelector && this.tabs.length > 1){
                this.$store.commit('toggleClientSelector');
            }
        },
    },
    computed: {
        selectedTab: function(){
            return this.tabs.filter(t => {
                return t.showing;
            })[0];
        },
        claimTabs: function(){
            return this.tabs.filter(tab => {
                return tab.claim == true;
            });
        },
    }
}
</script>

<style scoped>
.card-header-tabs.nav-tabs-for-dark .nav-link.active {
    border-bottom: 2px solid white;
    background-color: transparent;
}

.card-header-tabs.nav-tabs-for-dark .nav-link:hover {
    background-color: transparent;
    border-bottom: 2px solid white;
    font-weight: bold;
}

.tab-close:hover {
    color: red !important;
}

.claim-link:hover {
    color: dodgerblue;
    border-bottom: 1px solid dodgerblue;
}

.tab-reload:hover {
    color: lightgreen !important;
  }

.nav-item.active {
    border-bottom: 2px solid white;
  }
  
  .nav-item.active a {
    border-bottom: none!important;
  }
</style>
